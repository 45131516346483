.errorPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
  background: #F1F1F1;

  .error {
    font-size: 122px;
    font-weight: 600;
    color: #D11A6D;
  }

  .title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .info {
    font-size: 16px;
    margin-bottom: 60px;
  }
}